import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AudioPlayer = makeShortcode("AudioPlayer");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const Video = makeShortcode("Video");
const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "sand-talk",
      "style": {
        "position": "relative"
      }
    }}>{`Sand Talk`}<a parentName="h1" {...{
        "href": "#sand-talk",
        "aria-label": "sand talk permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p><img parentName="p" {...{
        "src": "/images/sand-talk.png",
        "alt": "Sand Talk"
      }}></img></p>
    <AudioPlayer src="https://kernel-audio.s3.amazonaws.com/sand_talk.mp3" mdxType="AudioPlayer" />
    <h2 {...{
      "id": "links-from-this-yarn",
      "style": {
        "position": "relative"
      }
    }}>{`Links from this yarn`}<a parentName="h2" {...{
        "href": "#links-from-this-yarn",
        "aria-label": "links from this yarn permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <List mdxType="List">
      <Link to="/learn/module-0/conversation" mdxType="Link">
        <p>{`Ongoing conversation`}</p>
      </Link>
      <Link to="https://andytudhope.africa/academy/communal-language/#love-this-sound" mdxType="Link">
        <p>{`The ideal of sound is harmony`}</p>
      </Link>
      <Link to="/learn/module-metta/relating-kernel/" mdxType="Link">
        <p>{`Nothing can be known or even exist unless it is in relation to other things`}</p>
      </Link>
      <Link to="https://finding.thebluebook.co.za/nine" mdxType="Link">
        <p>{`The sound made fresh`}</p>
      </Link>
      <Link to="/conversation/reciprocity" mdxType="Link">
        <p>{`Reciprocal relationships`}</p>
      </Link>
      <Link to="/learn/module-0/play-of-pattern" mdxType="Link">
        <p>{`Patterns of creation`}</p>
      </Link>
    </List>
    <h2 {...{
      "id": "same-dream-different-languages",
      "style": {
        "position": "relative"
      }
    }}>{`Same dream, different languages`}<a parentName="h2" {...{
        "href": "#same-dream-different-languages",
        "aria-label": "same dream different languages permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <blockquote>
      <p parentName="blockquote">{`"I think the real task lies in inverting institutions."`}</p>
    </blockquote>
    <p>{`We include below a rare video of Ivan Illich because he somehow manages to hint at much of the same essence Tyson is highlighting in his own way throughout the course of `}<em parentName="p">{`Sand Talk`}</em>{`. As Illich says at the beginning of this talk,`}</p>
    <blockquote>
      <p parentName="blockquote">{`"If we don't regain our ability to recognize the dream language `}{`[Pandora]`}{` can interpret; we are condemned, the world cannot survive."`}</p>
    </blockquote>
    <Video src="https://www.youtube-nocookie.com/embed/3mYlqojhn60?start=88" mdxType="Video" />
    <blockquote>
      <p parentName="blockquote">{`"This means transforming our language again."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"More and more, we define democracy as reaching consensus on the minimum that everyone `}<em parentName="p">{`should`}</em>{` have. I propose the inverse of this notion: the main goal of a healthy political system should be to establish the maximum that everyone can agree is enough for each person."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Epimetheus remained with the only gft that didn't escape `}{`[...]`}{` He is the man who stayed with Pandora, who closed her amphora before hope could escape. He is the man who stayed with his fellow beings `}{`[...]`}{` Our real problem today lies in regaining a deeply humanistic understanding of hope in our fellow humans, in this world, on this earth. Hope was alienated when it become the product of supernatural institutions."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"There must be a distinction between the hope we regain and hope in the sense of expectation. We must relearn how to distinguish between openness to being surprised by another and the expectation that the institution will give us the product that we learned to plan for and identify ahead of time. I think that rediscovering our ability to depend on each other, even in technological societies - which are in the worst condition of all societies, in the West and the East - is the greatest task of our time."`}</p>
    </blockquote>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p>{`For just a fleeting moment`}<br parentName="p"></br>{`
`}{`we are lent to each other.  `}</p>
        <p>{`We live because you draw us.`}<br parentName="p"></br>{`
`}{`We have colour because you paint us.`}<br parentName="p"></br>{`
`}{`We breathe because you sing us.`}<br parentName="p"></br>{`
`}{`But just for a fleeting moment,`}<br parentName="p"></br>{`
`}{`we are lent to each other.  `}</p>
        <p>{`Because we erase ourselves,`}<br parentName="p"></br>{`
`}{`like a drawing, even one made in obsidian,`}<br parentName="p"></br>{`
`}{`we lose our colour,`}<br parentName="p"></br>{`
`}{`as even the quetzatl loses its colour.`}<br parentName="p"></br>{`
`}{`We lose our sound, our breath
as does even the water's song.  `}</p>
        <p>{`But for just a fleeting moment`}<br parentName="p"></br>{`
`}{`we are lent to each other.  `}</p>
      </Box>
    </InfoBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      